'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.contacts.controller:ContactDataCtrl

 # @description

###
class ContactDataCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    ContactManager
    $q
    $scope
    $timeout
  ) ->
    @progress = true
    @typeModel = {}
    @dataModel = {}

    @templateOptions = ContactManager.templateOptions
    @errors = []

    init = =>
      @id = id
      @typeFields = ContactManager.getDataFieldsByType('dataType')

      @load = load
      @loadDataForm = loadDataForm
      @add = add
      @remove = remove
      @cancel = cancel
      @edit = edit

      @load()
      @loadDataForm()
    ##########

    add = ->
      if @editItem?
        @remove(@editItem).then(=>
          @editItem = undefined
        )

      @progress = true
      @dataModel.type = @typeModel.dataType
      $q.when(ContactManager.addData(@id, angular.copy(@dataModel)))
      .then(
        =>
          @typeModel = {}
          @dataFields = {}
          @dataModel = {}

          @load()
        (resp) =>
          @errors = extractErrors(resp.data.errors.children)
      )

    remove = (itemId) ->
      @progress = true
      $q.when(ContactManager.removeData(@id, itemId))
      .then =>
        @load()

    edit = (item) ->
      @editItem = item.id
      @items.splice(@items.indexOf(item),1)
      # console.log @typeModel
      # @dataModel = {}
      @typeModel.dataType = item.type
      # console.log item
      $timeout(=>
        if item.type == 'BirthDate' or item.type == 'DeathDate'
          @dataModel.value = new Date(item.value)
        else if item.type == "PoliceEmployee"
          @dataModel.p44Number = item.p44Number
        else
          @dataModel.value = item.value
      )

    load = ->
      ContactManager.one(@id)
      .then (data) =>
        @contactLabel = data.label
        @items = _.filter data.contactData ,(item) -> !item.deletedAt?
        @progress = false

    loadDataForm = =>
      if @typeModel.dataType?
        @dataFields = ContactManager.getDataFieldsByType(@typeModel.dataType)

    extractErrors = (errorsArray) ->
      errors = []
      for key, value of errorsArray
        if value.errors?
          errors.push('key': key, 'value': value.errors)
      errors

    cancel = ->
      $mdDialog.cancel()

    $scope.$watch 'ctrl.typeModel.dataType', () =>
      #remove value in value field first
      @dataModel = {}
      @loadDataForm()
    , true



    init()
angular
  .module('mundoAdmin.contacts')
  .controller 'ContactDataCtrl', ContactDataCtrl
